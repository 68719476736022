.menu {
    background-color: black;
    width: 0vw;
    min-width: 0vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .5s;
    z-index: 2;
}

.menu-active {
    background-color: black;
    min-width: 100vw;
}

.menu-package {
    min-width: 100vw !important;
}

.menu-open {
    min-width: 60vw;
}

.menu-logo {
    width: 160px;
    height: 160px;
    position: relative;
    left: 40px;
    top: 40px;
    transition: .5s;
    cursor: pointer;
}

.menu-logo-active {
    left: calc(100vw - 7vw - 2vw);
}

.menu-logo-small {
    width: 5.6vw;
    height: 5.6vw;
    left: calc(100vw - 5.6vw - 2vw);
}

.menu-logo__picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu-button {
    cursor: pointer;
    font-family: Cloud;
    font-size: 20px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    padding: 40px;
    width: fit-content;
}

.menu-category {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.menu-category__text {
    color: white;
    font-size: 4vw;
    font-weight: 200;
    cursor: pointer;
    padding: 2vw;
}

.menu-category__text-hover {
    width: 10vw;
    border-radius: 50%;
    position: absolute;
    transition: .5s;
    pointer-events: none;
    display: none;
}

.menu-category__text-hover-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(100%);
}

.menu-category__text-hover-one {
    right: -4vw;
    top: 0vw;
    display: block;
}

.menu-category__text-hover-one-ua {
    right: 4vw;
    top: .5vw;
    display: block;
}

.menu-category__text-hover-two {
    right: -1vw;
    top: 10vw;
    display: block;
}

.menu-category__text-hover-two-ua {
    right: 12vw;
    top: 10vw;
    display: block;
}

.menu-category__text-hover-three {
    right: 4.5vw;
    top: 19.5vw;
    display: block;
}

.menu-category__text-hover-three-ua {
    right: -4vw;
    top: 19.7vw;
    display: block;
}

.menu-category__black {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    transition: .3s;
}

.menu-category__black-open {
    width: 0%;
}

.menu-language {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 2.1vw;
    justify-content: flex-end;
    width: 7vw;
}

.menu-language__ua {
    border: 1px solid rgba(255, 255, 255, 0.30);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 2.9vw;
    height: 2.9vw;
    font-size: 0.7vw;
    font-weight: 200;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.menu-language__en {
    border: 1px solid rgba(255, 255, 255, 0.30);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 2.9vw;
    height: 2.9vw;
    font-size: 0.7vw;
    font-weight: 200;
    transition: .5s;
    position: absolute;
    bottom: 0;

    cursor: pointer;
    z-index: 1;
}

.position {
    bottom: 4vw;
}

.menu-contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #0D0D0D;
    color: white;
    cursor: pointer;
    position: absolute;
    transition: .5s;
    right: 0;
    bottom: 0;
    margin: 40px;
    font-size: 14px;
    z-index: 3;
}

.menu-contact-us__circle {
    width: 92px;
    height: 92px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 39px;
    background: linear-gradient(to bottom, white, #0D0D0D);
    border-radius: 50%;
    z-index: 2;
    transition: .5s;
}

.menu-contact-us__circle-open {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0));
}

.menu-contact-us-open {
    background: #32405b;
}

.menu-contact-us-by {
    width: 8vw;
    border-radius: 50px;
}

.menu-contact-us-black {
    background-color: #00000000;
    width: 100vw;
    height: 100vh;
    position: absolute;
    pointer-events: none;
    transition: .5s;
    z-index: 2;
}

.menu-contact-us-black-active {
    background-color: #00000099;
    pointer-events: auto;
}

.menu-contact-us-top {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #E9E9E999;

    position: absolute;
    right: 0;
    bottom: 0;
    margin: 40px;
    transition: .5s;
    z-index: 2;

    backdrop-filter: blur(25px);
}

.menu-contact-us-top-active {
    bottom: 120px;
    right: 120px;
}

.menu-contact-us-top-full {
    width: 705px;
    height: 728px;
    border-radius: 40px;
    padding: 80px 64px;
    background-color: #0D0D0DCC;
    border: 1px solid #E9E9E999;
    display: flex;
    flex-direction: column;
}

.menu-contact-us-top-title {
    font-size: 32px;
    margin-bottom: 26px;
    text-transform: uppercase;
    font-family: 'Wellingtons', 'sans-serif';
}

.menu-contact-us-top-input {
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 0.5px solid#FFFFFF4D;
    padding-bottom: 24px;
    color: white;
    margin-top: 56px;
    font-size: 16px;
    font-family: 'Wellingtons', 'sans-serif';
    resize: none;
    background-color: #0D0D0DCC;
    text-transform: uppercase;
    border-radius: none;
}

.error::placeholder {
    color: red !important;
}

.menu-contact-us-top-input::placeholder {
    color: white;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Wellingtons', 'sans-serif';
}

.menu-contact-us-top-checkbox__label {
    font-size: 16px;
    font-weight: 300;
    font-family: 'Wellingtons', 'sans-serif';
    cursor: pointer;
    margin-top: 2vw;
    display: flex;
    align-items: center;
    color: #FFFFFF80;
}

.menu-contact-us-top-checkbox {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 0.5px solid #FFFFFF80;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    margin-right: 24px;
}

.error-checkbox {
    border: 0.5px solid red;
}

.menu-contact-us-top-checkbox:checked {
    background-color: #FFFFFF80;
    border-color: #FFFFFF80;
}

.menu-contact-us-top-checkbox:checked::before {
    opacity: 1;
}

.menu-contact-us-button {
    font-family: 'Wellingtons', 'sans-serif';
    font-size: 16px;
    font-weight: 300;
    margin-top: 72px;

    min-height: 52px;
    border-radius: 12px;
    border: 0.5px solid #FFFFFF80;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-contact-us-top-send {
    width: 5.6vw;
    height: 5.6vw;
    right: 26.6vw;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    transition: .4s;
}

.menu-contact-us-top-finish {
    right: 0;
    width: 5.5vw;
    height: 5.5vw;
    margin: 40px;
}

.menu-contact-us-left {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.79);

    position: absolute;
    right: 0;
    bottom: 0;
    margin: 40px;
    transition: .5s;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    font-weight: 200;
    cursor: pointer;
    z-index: 3;
}

.menu-contact-us-left-active {
    right: 10vw;
    margin: 2.1vw;
    width: 5.6vw;
    height: 5.6vw;
}

.menu-contact-us-left-full {
    right: 26.6vw;
    width: 8.3vw;
    border-radius: 50px;
    background-color: white;
}

.menu-contact-us-left-send {
    width: 9vw;
    height: 5.6vw;
}

.menu-contact-us-left-finish {
    border: 1px solid rgba(255, 255, 255, 0);
    right: 0vw;
    width: 5.5vw;
    height: 5.5vw;
    margin: 2.15vw;
}

.menu-subcategory {
    height: 100%;
    width: 100vw;
    background-color: #171d29;
    position: absolute;
    z-index: 1;
}

.menu-subcategory__wrapper {
    position: absolute;
    width: 40vw;
    height: 100vh;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 3vw;
}

.menu-subcategory__text {
    color: rgba(255, 255, 255, 0.337);
    margin-left: 2vw;
    font-size: 2vw;
    font-weight: 200;
    animation: menuSubcategoryTextOn .7s forwards;
    animation-delay: .2s;
    opacity: 0;
}

@keyframes menuSubcategoryTextOn {
    0% {
        opacity: 0;
        margin-bottom: 2.5vw;
    }

    100% {
        margin-bottom: 2vw;
        opacity: 1;
    }
}

.menu-subcategory__specification {
    margin-left: 2vw;
    margin-bottom: auto;
    margin-top: 13vw;
    margin-right: 2.1vw;
    position: relative;
}

.menu-subcategory__specification-text {
    color: rgb(255, 255, 255);
    font-size: 1.2vw;
    font-weight: 200;
}

.menu-subcategory__info {
    background-color: #171d29;
    width: 100%;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    display: grid;
    grid-template-rows: 9vw calc(100% - 17vw) 6vw;
}

.info-title {
    color: white;
    margin-top: 3.5vw;
    font-size: 1.7vw;
    font-weight: 200;
}

.info-main {
    color: rgba(255, 255, 255, 0.694);
    font-size: 1vw;
    font-weight: 200;
    height: 65vh;
    overflow-y: scroll;
}

.info-main-ua {
    color: rgba(255, 255, 255, 0.694);
    font-size: 1.2vw;
    font-weight: 200;
    height: 65vh;
    overflow-y: scroll;
}

.info-main__price {
    color: rgba(255, 255, 255, 0.694);
    font-size: 1vw;
    font-weight: 200;
    margin-top: 2vw;
    border: 1.5px solid #32405b;
    border-radius: 30px;
    padding: 1vw 1.5vw;
    width: fit-content;
}

.info-main p {
    margin-bottom: 0.5vw;
}

.info-category {
    display: flex;
    flex-direction: column;
    gap: 0.7vw;
    justify-content: center;
}

.info-category__item {
    color: rgba(255, 255, 255, 0.50);
    font-size: 1.7vw;
    font-weight: 200;
    cursor: pointer;
}

.menu-subcategory__links {
    display: flex;
    flex-direction: column;
    margin-bottom: 1vw;
    position: relative;
    width: fit-content;
}

.menu-subcategory__links-blue {
    position: absolute;
    background-color: #171d29;
    width: 0;
    height: 100%;
    transition: .2s;
}

.menu-subcategory__links-blue-active {
    width: 100%;
}

.menu-subcategory__links-hover {
    width: 6vw;
    position: absolute;
    pointer-events: none;
    transition: .5s;
    display: none;
}

.menu-subcategory__links-hover-one {
    left: 12vw;
    bottom: 8vw;
    display: block;
}

.menu-subcategory__links-hover-one-ua {
    left: 10vw;
    bottom: 8vw;
    display: block;
}

.menu-subcategory__links-hover-two {
    left: 9vw;
    bottom: 3.2vw;
    display: block;
}

.menu-subcategory__links-hover-two-ua {
    left: 20vw;
    bottom: 3.2vw;
    display: block;
}

.menu-subcategory__links-hover-three {
    left: 12vw;
    bottom: -1vw;
    display: block;
}

.menu-subcategory__links-hover-three-ua {
    left: 11vw;
    bottom: -1.5vw;
    display: block;
}

.menu-subcategory__links-hover-one-basic {
    left: 7vw;
    bottom: 7vw;
    display: block;
}

.menu-subcategory__links-hover-two-pro {
    left: 6vw;
    bottom: 3.2vw;
    display: block;
}

.menu-subcategory__links-hover-three-plus {
    left: 7vw;
    bottom: -0.6vw;
    display: block;
}

.menu-subcategory__links-item {
    color: rgba(255, 255, 255, 0.50);
    font-size: 2.1vw;
    font-weight: 200;
    cursor: pointer;
    transition: .5s;
    padding: 0.8vw 0.8vw 0.8vw 0vw;
}

.menu-subcategory__links-item:hover {
    color: white;
}

.menu-subcategory__social {
    height: 9.8vw;
    display: flex;
    align-items: center;
    gap: 2vw;
}

.menu-subcategory__social-link__item {
    color: rgb(255 255 255 / 21%);
    font-size: 2.7vw;
}

@media (max-width: 1600px) {
    .menu {
        background-color: rgba(0, 0, 0, 0);
        height: 7vw;
        min-height: 7%;
        width: 100vw;
        grid-template-columns: 25% 50% 25%;
    }

    .menu-active {
        min-height: 100%;
        background-color: black;
    }

    .menu-package {
        min-height: 100% !important;
    }

    .menu-open {
        min-height: 50%;
    }

    .menu-title {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
    }

    .menu-title__letter {
        display: none;
    }

    .menu-title__open {
        opacity: 1;
    }

    .menu-title__burger {
        width: 7vw;
        height: 7vw;
        margin: 2.5vw;
    }

    .letter-bottom {
        margin-right: 2vw;
        font-size: 1.3vw;
    }

    .menu-category {
        top: 28%;
        left: 50%;
        width: max-content;
    }

    .menu-category__text-hover-one-ua {
        right: 3vw;
        top: -2vw;
    }

    .menu-category__text-hover-two-ua {
        right: 14vw;
        top: 9vw;
    }

    .menu-category__text-hover-three-ua {
        right: -6.5vw;
        top: 20vw;
    }

    .menu-category__text-hover-two {
        right: -3.5vw;
        top: 8.8vw;
    }

    .menu-category__text-hover-three {
        right: 3.5vw;
        top: 20.3vw;
    }

    .menu-category__text {
        font-size: 5vw;
    }

    .menu-category__text-hover {
        display: none;
    }

    .menu-category__black-open {
        height: 0%;
        width: 100%;
    }

    .menu-logo {
        width: 104px;
        height: 104px;
        left: 0;
        top: 0;
        margin: 40px;
    }

    .menu-logo-active {
        width: 12vw;
        height: 12vw;
    }

    .menu-logo-small {
        width: 12vw;
        height: 12vw;
    }

    .menu-language {
        width: auto;
        align-items: flex-end;
        justify-content: flex-start;
        margin: 2.5vw;
        bottom: 0;
    }

    .menu-language__ua {
        width: 7vw;
        height: 7vw;
        font-size: 1.6vw;
    }

    .menu-language-white {
        border: .8px solid rgb(0, 0, 0);
    }

    .menu-language__en {
        width: 7vw;
        height: 7vw;
        font-size: 1.6vw;
        top: 0;
        bottom: auto;
    }

    .position {
        top: 10vw;
    }

    .menu-subcategory__wrapper {
        width: 100vw;
        height: calc(50% - 2.5vw);
        bottom: 2.5vw;
        top: auto;
        padding-left: 0;
        align-items: center;
    }

    .menu-subcategory__info {
        padding-left: 0vw;
        padding-right: 3vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .menu-subcategory__specification {
        margin-left: 2vw;
        margin-bottom: auto;
        margin-top: 3vh;
        margin-right: 2.1vw;
    }

    .menu-subcategory__specification-text {
        font-size: 2.5vw;
        width: 90%;
    }

    .info-title {
        margin-top: 2vh;
        font-size: 4vw;
        margin-bottom: 2vh;
    }

    .info-main {
        font-size: 2vw;
        overflow-y: scroll;
        margin-bottom: auto;
        height: 32vh;
    }

    .info-main-ua {
        font-size: 2.5vw;
        height: 35vh;
        overflow-y: scroll;
        margin-bottom: auto;
    }

    .info-main__price {
        font-size: 2vw;
        margin-top: 4vw;
        border-radius: 30px;
        padding: 2vw 2.5vw;
    }

    .info-category {
        height: 13vw;
    }

    .info-category__item {
        font-size: 4vw;
    }

    .menu-subcategory__links {
        margin-bottom: 0;
        gap: 1vh;
        height: 100%;
        justify-content: center;
    }

    .menu-subcategory__links-item {
        font-size: 4vw;
    }

    .menu-subcategory__social {
        height: 14vw;
        gap: 4.5vw;
        margin-left: 5vw;
        margin-right: auto;
    }

    .menu-subcategory__social-link__item {
        font-size: 6vw;
    }

    .menu-subcategory__links-hover-one,
    .menu-subcategory__links-hover-one-ua,
    .menu-subcategory__links-hover-two,
    .menu-subcategory__links-hover-two-ua,
    .menu-subcategory__links-hover-three,
    .menu-subcategory__links-hover-three-ua,
    .menu-subcategory__links-hover-one-basic,
    .menu-subcategory__links-hover-two-pro,
    .menu-subcategory__links-hover-three-plus {
        display: none;
    }

    .menu-subcategory__text {
        font-size: 4vw;

    }

    @keyframes menuSubcategoryTextOn {
        0% {
            opacity: 0;
            margin-bottom: 5vw;
        }

        100% {
            margin-bottom: 3vw;
            opacity: 1;
        }
    }

    .menu-contact-us {
        width: 57px;
        height: 57px;
        right: 0;
        bottom: 0;
        margin: 40px;
        font-size: 10px;
    }

    .menu-contact-us-white {
        border: .8px solid black;
    }

    .menu-contact-us-white__border {
        border: none;
    }

    .menu-contact-us__ua {
        font-size: 2.5vw;
    }

    .menu-contact-us-by {
        width: 20vw;
        border-radius: 50px;
    }

    .menu-contact-us-left {
        width: 12vw;
        height: 12vw;
        margin: 3vw;
        font-size: 2.3vw;
    }

    .menu-contact-us-left-active {
        right: 20vw;
        margin: 2.5vw;
        width: 13vw;
        height: 13vw;
    }

    .menu-contact-us-left-full {
        right: 55vw;
        width: 20vw;
        border-radius: 50px;
    }

    .menu-contact-us-top {
        width: 56px;
        height: 56px;
        margin: 40px;
    }

    .menu-contact-us-top-active {
        margin: 2.5vw;
        bottom: 90px;
        right: 90px;
    }

    .menu-contact-us-top-full {
        width: 448px;
        height: 495px;
        border-radius: 25px;
        padding: 40px 50px;
    }

    .menu-contact-us-top-title {
        font-size: 20px;
        margin-bottom: 18px;
    }

    .menu-contact-us-top-input {
        font-size: 12px;
        margin-top: 35px;
    }

    .menu-contact-us-top-input::placeholder {
        font-size: 12px;
    }

    .menu-contact-us-top-checkbox__label {
        font-size: 12px;
        margin-top: 35px;
    }

    .menu-contact-us-top-checkbox {
        margin-right: 15px;
        width: 10px;
        height: 10px;
    }

    .menu-contact-us-top-send {
        width: 13vw;
        height: 13vw;
        right: 55vw;
        bottom: 0;
    }

    .menu-contact-us-top-finish {
        right: 0;
        width: 12vw;
        height: 12vw;
        margin: 3vw;
    }

    .menu-contact-us__circle {
        width: 59px;
        height: 59px;
        margin: 39px;
    }

    .menu-contact-us-button {
        font-size: 12px;
        margin-top: 45px;
        min-height: 40px;
        border-radius: 7px;
    }

}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {

    .letter-bottom {
        margin-right: 4vw;
        font-size: 2vw;
    }

    .menu-category {
        top: 28%;
        gap: 4.5vw;
        align-items: center;
    }

    .menu-title__burger {
        width: 11vw;
        height: 11vw;
        margin: 3vw;
    }

    .menu-category__text {
        font-size: 7vw;
    }

    .menu-category__text-hover {
        width: 15vw;
        height: 15vw;
    }

    .menu-category__text-hover-one-ua {
        right: 6vw;
        top: -1.5vw;
    }

    .menu-category__text-hover-two-ua {
        right: 21vw;
        top: 13vw;
    }

    .menu-category__text-hover-three-ua {
        right: -7vw;
        top: 27vw;
    }

    .menu-category__text-hover-one {
        right: -7.5vw;
        top: -2vw;
    }

    .menu-category__text-hover-two {
        right: -3vw;
        top: 12vw;
    }

    .menu-category__text-hover-three {
        right: 7.5vw;
        top: 27vw;
    }

    .menu-logo {
        width: 20vw;
        height: 20vw;
        margin: 3vw;
    }

    .menu-logo-active {
        width: 11vw;
        height: 11vw;
    }

    .menu-logo-small {
        width: 11vw;
        height: 11vw;
    }

    .menu-language {
        margin: 3vw;
    }

    .menu-language__ua {
        width: 11vw;
        height: 11vw;
        font-size: 3vw;
    }

    .menu-language__en {
        width: 11vw;
        height: 11vw;
        font-size: 3vw;
    }

    .position {
        top: 15vw;
    }

    .info-title {
        font-size: 5vw;
    }

    .info-category {
        max-height: 18vw;
        min-height: 18vw;
        gap: 2vw;
        margin-top: 5vw;
    }

    .info-category__item {
        font-size: 5vw;
    }

    .info-main-ua {
        font-size: 4.2vw;
    }

    .info-main {
        font-size: 3.5vw;
    }

    .info-main p {
        margin-bottom: 2vw;
    }

    .info-main__price {
        font-size: 3.3vw;
        margin-top: 6vw;
        padding: 3vw 3.5vw;
    }

    .menu-subcategory__wrapper {
        height: calc(50% - 3vw);
        bottom: 3vw;
    }

    .menu-subcategory__text {
        font-size: 4.5vw;
        margin-left: 0vw;
    }

    .menu-contact-us {
        width: 56px;
        height: 56px;
        font-size: 10px;
        margin: 20px;
    }

    .menu-contact-us__ua {
        font-size: 3.8vw;
    }

    .menu-contact-us-by {
        width: 27vw;
        border-radius: 50px;
    }

    .menu-contact-us-left {
        width: 17vw;
        height: 17vw;
        font-size: 3.5vw;
        margin: 3.5vw;
    }

    .menu-contact-us-left-active {
        right: 65vw;
        margin: 3vw;
        width: 18vw;
        height: 18vw;
    }

    .menu-contact-us-left-full {
        right: 69vw;
        width: 25vw;
        border-radius: 50px;
    }

    .menu-contact-us-top {
        width: 55px;
        height: 55px;
        margin: 21px;
    }

    .menu-contact-us-top-active {
        bottom: 13vh;
        right: 0;
    }

    .menu-contact-us-top-full {
        width: calc(100% - 40px);
        height: 390px;
        border-radius: 20px;
        padding: 40px 30px;
    }

    .menu-contact-us-top-title {
        font-size: 13px;
        margin-bottom: 12px;
    }

    .menu-contact-us-top-input {
        padding-bottom: 14px;
        margin-top: 28px;
        font-size: 10px;
    }

    .menu-contact-us-top-input::placeholder {
        font-size: 10px;
    }

    .menu-contact-us-top-checkbox__label {
        font-size: 10px;
        margin-top: 28px;
        align-items: flex-start;
    }

    .menu-contact-us-top-checkbox {
        min-width: 12px;
        min-height: 12px;
        margin-right: 10px;
    }

    .menu-contact-us-top-send {
        width: 18vw;
        height: 18vw;
        right: 70vw;
        bottom: 0;
    }

    .menu-contact-us-top-finish {
        right: 0;
        width: 18vw;
        height: 18vw;
        margin: 3vw;
    }

    .menu-subcategory__links-item {
        font-size: 6vw;
    }

    .menu-subcategory__social-link__item {
        font-size: 9vw;
    }

    .menu-subcategory__social {
        gap: 7vw;
        height: 25vw;
        margin-left: 8vw;
    }

    .menu-subcategory__links {
        margin-bottom: 3vh;
        margin-left: 0vw;
        align-items: center;
    }

    .menu-subcategory__specification {
        margin-left: 0vw;
        margin-top: 4vh;
        margin-right: 0vw;
    }

    .menu-subcategory__specification-text {
        font-size: 4.2vw;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .menu-contact-us__circle {
        width: 58px;
        height: 58px;
        margin: 19px;
    }

    .menu-contact-us-button {
        font-size: 10px;
        margin-top: 35px;
        min-height: 31px;
    }

}