.footer {
    width: 100vw;
    height: 100%;
    transition: 1.5s;
    background-color: black;
    display: flex;
    padding-left: 20vw;
    position: relative;
    overflow: hidden;
}

.footer-arrow__picture {
    width: 21vw;
    height: 21vw;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    transition: 1s;
    right: 3vw;
    opacity: 0;
    cursor: pointer;
}

.footer-arrow__picture-active {
    opacity: 1;
    right: -2vw;
}

.footer-arrow__picture-send {
    opacity: 0;
    right: -8.5vw !important;
}

.footer-arrow__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: 5vw;
    width: auto;
    height: 8vw;
}

.footer-arrow__img-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    filter: invert(100%);
    transform: rotate(-90deg);
}

.footer-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5vw;
    transition: 1s;
    opacity: 1;
}

.footer-wrapper-active {
    margin-left: 10%;
    opacity: 0;
}

.footer-wrapper__title {
    color: white;
    /* font-size: 60px; */
    font-size: 4vw;
    font-weight: 200;
}

.footer-wrapper__title-success {
    color: white;
    /* font-size: 60px; */
    font-size: 4vw;
    font-weight: 200;
    transition: 2s;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-80%);
    pointer-events: none;
}

.footer-wrapper__title-success-active {
    opacity: 1;
    transform: translateY(-50%);
}

.footer-wrapper__input {
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 0.5px solid rgb(255, 255, 255);
    /* padding-bottom: 5px; */
    padding-bottom: .5vw;
    color: #ffffff;
    /* margin-top: 40px; */
    margin-top: 2.5vw;
    /* font-size: 20px; */
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 200;
    font-family: 'Lemon Milk Pro', Kreadon;
    resize: none;
    background-color: black;
}

.footer-wrapper__input::placeholder {
    color: rgba(108, 145, 211, 0.70);
    font-size: 1.5vw;
    font-weight: 200 !important;
}

.footer-wrapper__checkbox__label {
    color: rgba(108, 145, 211, 0.70);
    font-size: 1.1vw;
    font-weight: 200 !important;
    font-family: 'Lemon Milk Pro', Kreadon;
    cursor: pointer;
    display: flex;
    margin-top: 2vw;
    align-items: center;
}

.footer-wrapper-checkbox {
    appearance: none;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 50%;
    margin-right: 1vw;
    border: 0.5px solid rgba(108, 145, 211, 0.70);
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.footer-wrapper-checkbox:checked {
    background-color: rgba(108, 145, 211, 0.70);
    border-color: rgba(108, 145, 211, 0.70);
}

.footer-wrapper-checkbox-error {
    border-color: red;
}

.footer-info {
    position: absolute;
    bottom: 2.1vw;
    display: flex;
    align-items: flex-end;
    width: 80vw;
    justify-content: space-between;
}

.footer-info__links {
    display: flex;
    /* gap: 30px; */
    gap: 2vw;
}

.footer-links__item {
    color: rgba(255, 255, 255, 0.529);
    /* font-size: 17px; */
    font-size: 1.2vw;
    font-weight: 200;
    cursor: pointer;
}

.footer-info__social {
    display: flex;
    /* gap: 20px; */
    gap: 1.5vw;
    margin-right: 6vw;
}

@media (max-width: 1200px) {
    .footer {
        flex-direction: column;
        align-items: center;
        padding-left: 0vw;
    }

    .footer-wrapper {
        margin-bottom: 0vh;
        margin-top: 25vh;
    }

    .footer-wrapper__title {
        font-size: 6vw;
    }

    .footer-wrapper__input {
        border-radius: 0;
        font-size: 3.5vw;
        margin-top: 5vw;
    }

    .footer-wrapper__input::placeholder {
        font-size: 3.5vw;
    }

    .footer-wrapper__checkbox__label {
        font-size: 2vw;
        margin-top: 3vh;
    }

    .footer-wrapper-checkbox {
        height: 2vw;
        width: 2vw;
        margin-right: 2vw;
    }

    .footer-arrow__picture {
        width: 30vw;
        height: 30vw;
        position: relative;
        bottom: 0;
        margin-top: auto;
        left: 50vw;
        transform: translateX(-60vw);
    }

    .footer-arrow__img {
        right: 8vw;
        height: 12vw;
    }

    .footer-arrow__picture-active {
        opacity: 1;
        transform: translateX(-50vw);
    }

    .footer-links__item {
        font-size: 2.4vw;
    }

    .footer-info {
        gap: 3vw;
        position: static;
        margin-top: auto;
        margin-bottom: 7vw;
        align-items: center;
    }

    .footer-info__social {
        gap: 3.4vw;
        margin-right: 0;
    }

    .footer-arrow__picture-send {
        opacity: 0;
        transform: translateX(0vw);
    }
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .footer-wrapper {
        margin-top: 16vh;
    }

    .footer-wrapper__title {
        font-size: 10vw;
        width: 85vw;
    }

    .footer-wrapper__input {
        margin-top: 4vh;
        font-size: 5.5vw;
        padding-bottom: 1vh;
    }

    .footer-wrapper__input::placeholder {
        font-size: 5.5vw;
    }

    .footer-wrapper__checkbox__label {
        font-size: 3.5vw;
        margin-top: 2vh;
    }

    .footer-wrapper-checkbox {
        width: 4vw;
        height: 4vw;
        margin-right: 3vw;
    }

    .footer-arrow__picture {
        width: 50vw;
        height: 50vw;
        position: relative;
        bottom: 0;
        margin-top: auto;
        left: 50vw;
        transform: translateX(-70vw);
    }

    .footer-arrow__img {
        right: 11vw;
        height: 18vw;
    }

    .footer-arrow__picture-active {
        opacity: 1;
        transform: translateX(-50vw);
    }

    .footer-links__item {
        font-size: 3.6vw;
    }

    .footer-info {
        flex-direction: column-reverse;
        width: 85vw;
        margin-bottom: 0;
    }

    .footer-info__links {
        margin-right: auto;
        gap: 5vw;
        margin-bottom: 3vh;
    }

    .footer-info__social {
        display: none;
    }

    .footer-arrow__picture-send {
        opacity: 0;
        transform: translateX(0vw);
    }

    .footer-wrapper__title-success {
        font-size: 6vw;
        width: 80vw;
        transform: translateY(-150%);
    }

    .footer-wrapper__title-success-active {
        transform: translateY(-50%);
    }


}