/* @font-face {
    font-family: 'Kreadon';
    font-style: normal;
    font-weight: 200;
    src: local('Kreadon'), url(../public/fonts/Kreadon.otf) format('opentype');
}

@font-face {
    font-family: 'Cloud';
    font-style: normal;
    font-weight: 200;
    src: local('Cloud'), url(../public/fonts/Cloud.otf) format('opentype');
} */

@font-face {
    font-family: 'Wellingtons';
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/TT_Wellingtons_Light.woff2) format('woff2');
}

@font-face {
    font-family: 'Wellingtons';
    font-style: normal;
    font-weight: 100;
    src: url(../public/fonts/TT_Wellingtons_Thin.woff2) format('woff2');
}

body {
    font-family: 'Wellingtons', sans-serif;
    font-weight: 300;
}