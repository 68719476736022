.home {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 4;
    transition: .8s;
    transform: translateY(0%);
    justify-content: center;
    gap: 7vh;
}

.home-one {
    transform: translateY(-10%);
    pointer-events: none;
}

.home__picture {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .8s;
}

.home__picture-one {
    height: 10vh;
}

.home__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #0D0D0D;
}

.home-description {
    z-index: 1;
    transition: .5s;
    opacity: 0;
    text-align: center;
    font-size: 16px;
    white-space: pre-line;
    width: 700px;
    margin-bottom: 2vh;
    font-weight: 100;



    line-height: 22.4px;
    letter-spacing: 0.02em;
}

.home-description-active {
    opacity: 1;
}

.home-gradient-text {
    font-size: 120px;
    color: transparent;
    z-index: 1;
    opacity: 0;

    background: linear-gradient(to right, #000000 25%, #a4caf2 50%, #000000 75%);
    -webkit-background-clip: text;
    background-clip: text;

    background-position: 50% 0%;
    background-size: 250%;
    margin-top: 150px;
    font-weight: 300;
}


.home-gradient-text-active {
    animation: opacity .7s linear forwards;
}

@media (max-width: 1600px) {
    .home-gradient-text {
        font-size: 74px;
    }

    .home-description {
        font-size: 12px;
        line-height: 13.2px;
    }
}

@media (max-width: 1024px) {

    .home__picture {
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .8s;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0D0D0D;
    }

    .home__img {
        width: 95%;
        height: 95%;
        object-fit: contain;

    }

    .home-gradient-text {
        font-size: 65px;
        margin-top: 20px;
    }

    .home {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        z-index: 4;
        transition: .8s;
        transform: translateY(0%);
        justify-content: center;
        gap: 3vh;
    }

}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .home__img {
        width: 100%;
        height: 75%;
        object-fit: cover;
    }

    .home-gradient-text {
        font-size: 46px;
        margin-top: 20px;
    }

    .menu-logo {
        width: 80px;
        height: 80px;
        margin: 20px;
    }

    .home-description {
        font-size: 12px;
        line-height: 19.2px;
        width: 70%;
    }

}