*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input:active,
input:focus {
  outline: none;
}

textarea:active,
textarea:focus {
  outline: none;
}

body {
  overflow: hidden !important;
  color: #E9E9E9;
  background-color: #0D0D0D;
}

a,
button {
  text-decoration: none !important;
  color: inherit;
}

ul,
li {
  list-style: none;
}

button {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  padding: 0;
  cursor: pointer;
  font-family: 'Kreadon', 'sans-serif';
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* стрелочка */
.arrow__picture {
  position: absolute;
  width: 320px;
  height: 320px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  cursor: pointer;
  transition: 1s;
  opacity: 0;
}

.arrow__img {
  height: 110px;
  width: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 105px;
}

.arrow__picture-active {
  bottom: -50px;
  opacity: 1;
}

.arrow__picture-active__portfolio-case {
  bottom: -17vw;
}

.arrow__img-circle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.arrow__md {
  z-index: 1;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .arrow__picture {
    width: 33vw;
    height: 33vw;
    bottom: 10vw;
  }

  .arrow__picture-active {
    bottom: 7vw;
    opacity: 1;
  }

  .arrow__picture-active__portfolio-case {
    bottom: -33vw;
  }

  .arrow__img {
    height: 12vw;
    top: 12vw;
  }
}

@media (max-width: 576px) {
  .arrow__img {
    height: 12vw;
    top: 16vw;
  }

  .arrow__picture {
    width: 36vw;
    height: 36vw;
    bottom: 12vw;
  }

  .arrow__picture-active {
    bottom: 5vw;
    opacity: 1;
  }

  .arrow__picture-active__portfolio-case {
    bottom: -36vw;
  }
}