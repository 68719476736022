.portfolio {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateY(50%);
    transition: .8s;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.portfolio-one {
    transform: translateY(0%);
}

.portfolio-two {
    transform: translateY(-10%);
    pointer-events: none;
}

.portfolio__picture {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .8s;
    background-color: #0D0D0D;
}

.portfolio__picture-two {
    height: 10vh;
}

.portfolio__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolio-black {
    height: 100vh;
    width: 0vw;
    margin-right: auto;
    z-index: 1;
    transition: .5s;
}

.portfolio-black-active__case {
    width: 100vw;
    background-color: #0D0D0D;
}

.portfolio-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 64px;
    opacity: 0;
    animation: opacity .5s linear forwards;
    animation-delay: .2s;
    top: 6%;
    z-index: 0;
}

.portfolio-photoOne {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20vw;
    width: 255px;
    height: 240px;
    transition: .7s;
    border: 1px solid white;
    cursor: pointer;
}

.portfolio-photoTwo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20vw;
    width: 255px;
    height: 240px;
    transition: .7s;
    border: 1px solid white;
    cursor: pointer;
}

.portfolio-photoThree {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25vw;
    width: 300px;
    height: 240px;
    transition: .7s;
    border: 1px solid white;
    cursor: pointer;
}

.portfolio-photo-startOne {
    left: 17vw;
}

.portfolio-photo-startTwo {
    left: 32vw;
}

.portfolio-photo-startThree {
    left: 47vw;
}

.portfolio-button {
    width: 265px;
    height: 60px;
    background-color: white;
    position: absolute;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: opacity .5s linear forwards;
    animation-delay: .2s;
    left: 65vw;
    top: 50%;
    opacity: 0;
    cursor: pointer;
    transform: translateY(205%);
    border-radius: 50px;
    border: none;
    font-size: 16px;
    box-shadow: 0px 10px 21px 0px rgba(200, 203, 254, 0.20), 0px 39px 39px 0px rgba(200, 203, 254, 0.17), 0px 87px 52px 0px rgba(200, 203, 254, 0.10), 0px 155px 62px 0px rgba(200, 203, 254, 0.03), 0px 242px 68px 0px rgba(200, 203, 254, 0.00);
}

.portfolio-button:hover {
    box-shadow: 0px 10px 21px 0px rgba(200, 203, 254, 0.456), 0px 39px 39px 0px rgba(200, 203, 254, 0.297), 0px 87px 52px 0px rgba(200, 203, 254, 0.10), 0px 155px 62px 0px rgba(200, 203, 254, 0.03), 0px 242px 68px 0px rgba(200, 203, 254, 0.00);
}

@media (max-width: 1200px) {

    .portfolio-black {
        background-color: rgb(0 0 0 / 47%);
    }

    .portfolio-black-active {
        width: 100vw;
    }

    .portfolio-black-active__case {
        background-color: rgb(0, 0, 0);
    }

    .portfolio-title {
        font-size: 10vw;
        top: 34%;
        transform: translateY(-167%);
        left: -50%;
        color: white;
    }

    .portfolio-title-start {
        transform: translateY(-167%) translateX(-50%);
        left: 50%;
    }

    .portfolio-photo {
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        height: 100vh;
    }

    .portfolio-photoOne {
        top: 42%;
        transform: translateY(-50%);
        left: -30vw;
        width: 30vw;
        height: 30vw;
    }

    .portfolio-photo-startOne {
        left: 10vw;
    }

    .portfolio-photoTwo {
        top: 42%;
        transform: translateY(-50%);
        left: -30vw;
        width: 30vw;
        height: 30vw;
    }

    .portfolio-photo-startTwo {
        left: 45vw;
    }

    .portfolio-photoThree {
        top: 42%;
        transform: translateY(-50%);
        left: -30vw;
        width: 30vw;
        height: 30vw;
    }

    .portfolio-photo-startThree {
        left: 80vw;
    }

    .portfolio-photoFake {
        width: 30vw;
        height: 30vw;
        left: 90vw;
        position: absolute;
        top: 42%;
    }

    .portfolio-button {
        width: 33vw;
        height: 8vw;
        left: 50%;
        top: 50%;
        transform: translateY(200%) translateX(-50%);
        border-radius: 50px;
        font-size: 2.3vw;
    }

    .portfolio-button-ua {
        font-size: 2.7vw;
    }
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    /* .portfolio-arrow__img {
        height: 12vw;
        top: 16vw;
    }

    .portfolio-arrow__picture {
        width: 36vw;
        height: 36vw;
        bottom: 12vw;
    }

    .portfolio-arrow__picture-active {
        bottom: 5vw;
        opacity: 1;
    } */

    .portfolio-title {
        font-size: 9vw;
        top: 45vw;
        left: -60%;
    }

    .portfolio-title-start {
        left: 50%;
    }

    .portfolio-photoOne {
        top: 44vw;
        transform: none;
        left: -50vw;
        width: 50vw;
        height: 50vw;
    }

    .portfolio-photo-startOne {
        left: 10vw;
    }

    .portfolio-photoTwo {
        top: 44vw;
        transform: none;
        left: -50vw;
        width: 50vw;
        height: 50vw;
    }

    .portfolio-photo-startTwo {
        left: 70vw;
    }

    .portfolio-photoThree {
        top: 44vw;
        transform: none;
        left: -50vw;
        width: 50vw;
        height: 50vw;
    }

    .portfolio-photo-startThree {
        left: 130vw;
    }

    .portfolio-photoFake {
        left: 160vw;
    }

    .portfolio-button {
        width: 60vw;
        height: 12vw;
        left: 50%;
        top: 80vw;
        transform: translateY(200%) translateX(-50%);
        border-radius: 50px;
        font-size: 3.5vw;
    }

    .portfolio-button-ua {
        font-size: 4vw;
    }
}