.workflow {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateY(50%);
    transition: .7s;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.workflow-two {
    transform: translateY(0%);
}

.workflow-three {
    transform: translateY(-10%);
    pointer-events: none;
}

.workflow-title {
    position: absolute;
    font-size: 5vw;
    font-weight: 200;
    color: #FFFFFFB2;
    top: 30%;
    opacity: 0;
    transform: translateY(-50%);
    transition: .5s;
    z-index: 1;
}

.workflow-title-ua {
    font-size: 5.5vw;
}

.workflow-title-active {
    opacity: 1;
}

.workflow__picture {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .7s;
}

.workflow__picture-three {
    height: 10vh;
}

.workflow__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s;
}

.workflow__img-active {
    filter: brightness(.4);
}

.workflow-stages-container {
    width: 80vw;
    height: 70vh;
    z-index: 1;
    opacity: 0;
    transition: .2s;
}

.workflow-stages-container-active {
    opacity: 1;
}

.workflow-stages-container__text {
    height: 25vh;
    display: flex;
    gap: 5vw;
    margin-left: 8vw;
}

.workflow-stages-container__title {
    color: white;
    font-size: 3vw;
    font-weight: 200;
    animation: title .5s linear forwards;
}

.workflow-stages-container__title-ua {
    font-size: 3.5vw;
}

.workflow-stages-container__description {
    height: 100%;
    width: 75%;
    display: flex;
    gap: 4vh;
    flex-direction: column;
    animation: title .5s linear forwards;
}

@keyframes title {
    0% {
        margin-top: -10px;
        opacity: 0;
    }

    100% {
        margin-top: 0px;
        opacity: 1;
    }
}

.workflow-stages-container__description-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1.5vw;
}

.workflow-stages-container__description-info__line {
    width: 100%;
    height: 0.5px;
    margin-left: 10px;
    background-color: white;

}

.workflow-stages-container__description-info__text {
    color: white;
    font-size: 1.1vw;
    font-weight: 200;
    line-height: 1.6;
}

.workflow-stages-container__description-info__text-ua {
    color: white;
    font-size: 1.3vw;
    font-weight: 200;
}

.workflow-stages-container__circles {
    position: absolute;
    height: 30vw;
    top: 60%;
    transform: translateY(-50%);
}

.workflow-stages-container__circles-line {
    position: absolute;
    height: 1px;
    width: 0vw;
    left: 0;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
    background-image: linear-gradient(to left, #6C91D3, black);
    transition: width 0.9s linear;
}

.workflow-stages-container__circles-line-active {
    width: 90vw;
}

.workflow-stages-container__circles-circle {
    width: 0vw;
    height: 0vw;
    border-radius: 50%;
    border: .5px solid white;
    background-color: black;
    position: absolute;
    top: 50%;
    left: 5vw;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .7s;
    cursor: pointer;
}

.workflow-stages-circle__two {
    left: 25vw;
}

.workflow-stages-circle__three {
    left: 45vw;
}

.workflow-stages-circle__four {
    left: 65vw;
}

.workflow-stages-circle-start {
    width: 12vw;
    height: 12vw;
}

.workflow-stages-circle-active {
    background-color: white;
    transition: .5s;
    box-shadow: 0px 10px 21px 0px rgba(200, 203, 254, 0.20), 0px 39px 39px 0px rgba(200, 203, 254, 0.17), 0px 87px 52px 0px rgba(200, 203, 254, 0.10), 0px 155px 62px 0px rgba(200, 203, 254, 0.03), 0px 242px 68px 0px rgba(200, 203, 254, 0.00);
}

.workflow-stages-container__circles-circle__number {
    color: white;
    font-size: 2vw;
    font-weight: 200;
    animation: text-opacity .5s linear forwards;
    opacity: 1;
}

.workflow-stages-container__circles-circle__number-active {
    color: black;
}

.workflow-stages-container__circles-circle__text {
    font-size: 1.4vw;
}

@media (max-width: 1200px) {
    .workflow-title {
        font-size: 7.5vw;
    }

    .workflow-title-ua {
        font-size: 8vw;
    }

    .workflow-stages-container {
        width: 90vw;
        height: 60vh;
    }

    .workflow-stages-container__circles-line-active {
        width: 80vw;
    }

    .workflow-stages-container__circles-line {
        transition: width 0.6s linear;
    }

    .workflow-stages-container__text {
        margin-left: 3vw;
    }

    .workflow-stages-container__circles {
        top: 55%;
    }

    .workflow-stages-container__circles-circle {
        left: 0vw;
    }

    .workflow-stages-circle__two {
        left: 24vw;
    }

    .workflow-stages-circle__three {
        left: 48vw;
    }

    .workflow-stages-circle__four {
        left: 72vw;
    }

    .workflow-stages-circle-start {
        width: 18vw;
        height: 18vw;
    }

    .workflow-stages-container__title {
        font-size: 5vw;
    }

    .workflow-stages-container__title-ua {
        font-size: 5.5vw;
    }

    .workflow-stages-container__description-info__text {
        font-size: 1.6vw;
        line-height: 1.7;
    }

    .workflow-stages-container__description-info__text-ua {
        font-size: 2.2vw;
    }

    .workflow-stages-container__circles-circle__number {
        font-size: 3vw;
    }

    .workflow-stages-container__circles-circle__text {
        font-size: 2vw;
    }

}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .workflow-title {
        font-size: 9vw;
    }

    .workflow-title-ua {
        font-size: 10vw;
    }

    .workflow-stages-container {
        width: 90vw;
        height: 65vh;
    }

    .workflow-stages-container__description {
        width: 95%;
        flex-direction: column;
    }

    .workflow-stages-container__text {
        margin-left: 0vw;
        flex-direction: column;
        align-items: center;
    }

    .workflow-stages-container__description-info__line {
        margin-left: 0px;
    }

    .workflow-stages-container__title {
        font-size: 7vw;
    }

    .workflow-stages-container__title-ua {
        font-size: 8vw;
    }

    .workflow-stages-container__description-info__text {
        font-size: 3vw;
        line-height: 1.3;
        text-align: center;
        padding-left: 3px;
        padding-right: 3px;
    }


    .workflow-stages-container__description-info__text-ua {
        font-size: 3.5vw;
    }

    .workflow-stages-container__circles::-webkit-scrollbar {
        width: 0.5em;
    }

    .workflow-stages-container__circles::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .workflow-stages-container__circles {
        top: 60%;
        height: 100vh;
        width: 100%;
        left: 0;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
        overflow-y: scroll;
    }

    .workflow-stages-circle-start {
        width: 25vw;
        height: 25vw;
    }

    .workflow-stages-container__circles-circle {
        left: 3vw;
    }

    .workflow-stages-circle__two {
        left: 34vw;
    }

    .workflow-stages-circle__three {
        left: 65vw;
    }

    .workflow-stages-circle__four {
        left: 95vw;
    }

    .workflow-fake {
        left: 105vw;
        width: 20vw;
        height: 20vw;
        position: absolute;
        top: 42%;
    }

    .workflow-stages-circle-active {
        width: 28vw;
        height: 28vw;
    }

    .workflow-stages-container__circles-line {
        transition: width 0.5s linear;
    }

    .workflow-stages-container__circles-line-active {
        width: 100%;
    }

    .workflow-stages-container__circles-circle__number {
        font-size: 5vw;
    }

    .workflow-stages-container__circles-circle__number-active {
        font-size: 6vw;
    }

    .workflow-stages-container__circles-circle__text {
        font-size: 3vw;
    }
}