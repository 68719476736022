.ourProducts {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateY(50%);
    transition: .8s;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ourProducts-one {
    transform: translateY(0%);
}

.ourProducts-two {
    transform: translateY(-10%);
    pointer-events: none;
}

.ourProducts__picture {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .8s;
    background-color: #0D0D0D;

    display: flex;
    align-items: center;
    justify-content: center;
}

.ourProducts__picture-two {
    height: 10vh;
}

.ourProducts__picture-cover {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    display: flex;
}

.ourProducts__picture-big {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.4);
    transition: .8s;
}

.ourProducts__picture-big-start {
    filter: brightness(1);
}


.ourProducts__picture-centr {
    min-width: 400px;
    max-width: 400px;
    min-height: 400px;
    margin: 50px;
    height: 100%;
    transition: .7s;
}

.ourProducts__picture-centr-start {
    margin: 0px;
}

.ourProducts__picture-centr-off {
    margin-bottom: 400px;
}

.ourProducts-gradient-text {
    z-index: 1;
    opacity: 0;
    animation: opacity .5s linear forwards;
    /* animation-delay: .5s; */
    margin-top: 70px;

    font-size: 64px;
}

.ourProducts__internet {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    opacity: 0;
    animation: opacity .5s linear forwards;
    animation-delay: .2s;
}

.ourProducts__internet-number {
    font-family: Cloud;
    font-size: 64px;
    line-height: 67.84px;
}

.ourProducts__internet-text {
    font-family: Kreadon;
    font-size: 32px;
    line-height: 38.4px;
}

.ourProducts__left {
    font-size: 48px;
    z-index: 1;
    position: absolute;
    left: 10vw;
    top: 60%;
    opacity: 0;
    animation: opacity .5s linear forwards;
    animation-delay: .2s;
}

.ourProducts__right {
    font-size: 48px;
    z-index: 1;
    position: absolute;
    right: 10vw;
    top: 60%;
    opacity: 0;
    animation: opacity .5s linear forwards;
    animation-delay: .2s;
}